<template>
  <v-card>
    <!-- start of toolbar 2 -->
    <v-toolbar color="secondary" flat dense>
      <v-btn
        class="mr-1"
        outlined
        color="toolbarIcon"
        text
        @click="sendData"
        :loading="loader"
      >
        <v-icon left dark>mdi-content-save</v-icon>Save
      </v-btn>
    </v-toolbar>
    <!-- end of toolbar 2 -->
    <v-card-text>
      <v-row>
        <v-col cols="4">
          <v-text-field outlined dense v-model="record.Name" label="Name"></v-text-field>
        </v-col>
        <v-col cols="4">
          <v-text-field
            outlined
            dense
            type="number"
            v-model="record.Code"
            label="Code"
          ></v-text-field>
        </v-col>
        <v-col cols="4">
          <v-checkbox label="Active" v-model="record.Active"></v-checkbox>
        </v-col>
        <v-col cols="4">
          <v-autocomplete
            dense
            outlined
            v-model="record.Type"
            :items="actionType"
            item-text="text"
            item-value="value"
            label="Type"
          ></v-autocomplete>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  props: {
    initial: {
      type: Object,
    },
  },
  data() {
    return {
      record: {},
      loader: null,
      search: null,
      actionType: [
        { text: "Actions", value: 2 },
        { text: "Subjects", value: 1 },
      ],
    };
  },

  watch: {
    initial: {
      handler: "init",
      immediate: true,
    },
  },
  methods: {
    init(val) {
      this.record = { ...val };
    },
    sendData() {
      this.$store.commit("loader", true);
      this.$emit("data", this.record);
    },
  },
};
</script>
