<template>
  <v-container fluid>
    <v-card>
      <v-toolbar color="secondary" flat dense>
        <v-toolbar-title>Edit</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn color="primary" small text @click="goBack">
          <v-icon left dark>mdi-skip-backward</v-icon>Back
        </v-btn>
      </v-toolbar>
      <v-card-text>
        <!-- editor -->
        <editor :initial="dataItem" :docCreate="docCreate" @data="save"></editor>
        <!-- end -->
        <snackbar ref="snackbar"></snackbar>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import Editor from "../_components/editor.vue";
export default {
  components: {
    Editor,
  },
  data: () => ({
    dataItem: {},
    docCreate: false,
  }),
  watch: {
    "$route.params.id": {
      handler: "getData",
      immediate: true,
    },
  },
  methods: {
    getData(id) {
      console.log(this.$route.query.Type);
      const self = this;
      this.$store
        .dispatch("get", `/subjects_and_actions/${id}?Type=${this.$route.query.Type}`)
        .then((res) => {
          self.dataItem = res.ResponseData;
          self.dataItem.Type = parseInt(this.$route.query.Type);
          console.log(self.dataItem);
        })
        .catch((err) => {
          this.$refs.snackbar.show(err, "red");
        });
    },
    save(data) {
      const url = `/subjects_and_actions/${data.id}`;
      const self = this;
      this.$store
        .dispatch("put", { url, data })
        .then((res) => {
          this.$store.commit("loader", false);
          if (res.ResultCode == 1200) {
            self.$refs.snackbar.show(res.ResultDesc, "green");
            this.goBack();
          } else {
            self.$refs.snackbar.show(res.ResultDesc, "red");
          }
        })
        .catch((err) => {
          this.$store.commit("loader", false);
          this.$refs.snackbar.show(err.response.data.errors, "red");
        });
    },
    goBack() {
      this.$router.go(-1);
    },
  },
};
</script>
